<template>
    <div>
      <q-form ref="editForm">
        <c-card class="cardClassDetailForm passwordChangeArea">
          <template slot="card-title-custom">
            <div class="titleArea">
              <div class="title">
                “고객님의 비밀번호를 <font class="emphasis">변경</font>해 주세요.”
              </div>
              <div class="subtitle" v-html="subtitle">
              </div>
            </div>
          </template>
          <template slot="card-detail">
            <!-- <div class="col-12">
              <c-label-text 
                title="아이디" 
                style="padding-bottom: 25px !important;"
                :value="userInfo.loginId" />
            </div> -->
            <div class="col-12">
              <c-text
                :required="true"
                label="기존 비밀번호"
                name="oripassword"
                style="padding-bottom: 25px !important;"
                type="password"
                v-model="userInfo.oripassword">
              </c-text>
            </div>
            <div class="col-12">
              <c-text
                :required="true"
                label="변경 비밀번호"
                name="password"
                type="password"
                v-model="userInfo.password">
              </c-text>
            </div>
            <div class="col-12">
              <c-text
                :required="true"
                label="변경 비밀번호 확인"
                name="passwordConf"
                type="password"
                v-model="userInfo.passwordConf">
              </c-text>
            </div>
            <div class="col-12" style="text-align:right">
              <q-btn-group outline >
                <c-btn label="다음에 변경하기" icon="redo" @btnClicked="nextChangePw" />
                <c-btn label="비밀번호 변경" icon="save" @btnClicked="savePw" />
              </q-btn-group>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
  </template>
  <script>
  import transactionConfig from '@/js/transactionConfig';
  import { encrypt } from '@/js/aes256';
  export default {
    name: 'initChangePw',
    props: {
      popupParam: {
        type: Object,
        default: function() {
          return {
            aesLoginId: '',
            message: '',
          }
        },
      },
    },
    data() {
      return {
        userInfo: {
          aesLoginId: '',
          oripassword: '',
          password: '',
          passwordConf: '',
          chgUserId: '',
        },
        saveUrl: '',
        nextChangeUrl: '',
      };
    },
    computed: {
      subtitle() {
        let text = '✔ 고객님의 소중한 개인정보를 보호하기 위하여 비밀번호 변경을 안내해 드리고 있습니다.<br/>'
          + '✔ 정기적인 비밀번호 변경으로 고객님의 개인정보를 보호해 주세요.<br/>'
          + '❗❗ 비밀번호는 8자리 이상 소문자/대문자/숫자/특수문자 중 3개 이상 조합하여 작성하세요.'
        if (this.popupParam.message) {
          text = `✔ ${this.popupParam.message} <br/>` + text
        }
        return text
      }
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {
    },
    methods: {
      init() {
        // role setting
        // url setting
        this.saveUrl = transactionConfig.sso.changepw.url;
        this.nextChangeUrl = transactionConfig.sso.nextChangePw.url;
        // code setting
        if (this.popupParam.aesLoginId) {
          this.$set(this.userInfo, 'aesLoginId', this.popupParam.aesLoginId)
        } 
        // list setting
      },
      savePw() {
        let passwordReg = {
          lowerCase: /[a-z]/g,
          upperCase: /[A-Z]/g,
          numberCase: /[0-9]/g,
          /* eslint-disable-next-line */
          specialCase: /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g,
          lowerCaseTest: false,
          upperCaseTest: false,
          numberCaseTest: false,
          specialCaseTest: false,
        };
        if (this.userInfo.oripassword === '' || this.userInfo.password === '' || this.userInfo.passwordConf === '') {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '비밀번호를 입력하세요!',
            type: 'warning', // success / info / warning / error
          });
        } else if (this.userInfo.password !== this.userInfo.passwordConf) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '변경비밀번호와 비밀번호 확인이 다릅니다.',
            type: 'warning', // success / info / warning / error
          });
        } else {
          /**
           * 비밀번호 조건
           *  - 패스워드 길이 최소 8자리 이상
           *  - 대문자/소문자/숫자/특주문자 중 3개 포함
           */
          this.$set(passwordReg, 'lowerCaseTest', passwordReg.lowerCase.test(this.userInfo.password));
          this.$set(passwordReg, 'upperCaseTest', passwordReg.upperCase.test(this.userInfo.password));
          this.$set(passwordReg, 'numberCaseTest', passwordReg.numberCase.test(this.userInfo.password));
          this.$set(passwordReg, 'specialCaseTest', passwordReg.specialCase.test(this.userInfo.password));
          
          let resultTest = this.$_.filter(passwordReg, (value) => {
            return value === false
          })
          if (resultTest.length > 1 || this.userInfo.password.length < 8) {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '비밀번호는 최소 8자리여야 하며,\n 대문자/소문자/숫자/특주문자 중 3개를 포함해야합니다.',
              type: 'warning', // success / info / warning / error
            });
            return;
          } else {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '변경하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {          
                this.$http.url = this.saveUrl;
                this.$http.type = 'POST';
                this.$http.param = {
                  aesLoginId: this.userInfo.aesLoginId,
                  oripassword: encrypt(this.userInfo.oripassword),
                  password: encrypt(this.userInfo.password),
                  passwordConf: encrypt(this.userInfo.passwordConf),
                  chgUserId: this.userInfo.chgUserId,
                };
                this.$http.request((_result) => {
                  if (_result.data === 'W') {
                    window.getApp.$emit('ALERT', {
                      title: '안내', // 안내
                      message: '기존 비밀번호가 틀립니다.',
                      type: 'warning', // success / info / warning / error
                    });
                  } else {
                    window.getApp.$emit('APP_REQUEST_SUCCESS');
                    this.$emit('closePopup', 'change');
                  }
                },);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      },
      nextChangePw() {
        console.log('nextChangeUrl', this.nextChangeUrl)
        window.getApp.$emit('CONFIRM', {
          title: '확인', //'확인',
          message: '다음에 변경하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {          
            this.$http.url = this.nextChangeUrl;
            this.$http.type = 'PUT';
            this.$http.param = {
              aesLoginId: this.userInfo.aesLoginId,
              chgUserId: this.userInfo.chgUserId,
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$emit('closePopup', 'change');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      },
    }
  };
  </script>
  <style lang="sass">
  .passwordChangeArea
    .titleArea
      width: 100%
      .title
        font-size:2em
        font-weight: 700
        text-align: center
        .emphasis
          color: #C10015
      .subtitle
        font-size:1.05em
        font-weight: 500
  </style>